<template>
  <div class="page">
    <div class="infoBox">
      <div class="banner">
        <img :src="detail.topicPicture" alt="" />
      </div>
    </div>
    <v-refresh v-model="isLoading" @refresh="onRefresh" class="main">
      <div class="content">
        <div
          class="text-empty"
          v-if="detail.topicContent && detail.topicContent.length === 0"
        >
          暂无文本
        </div>
        <div v-else v-html="detail.topicContent"></div>
      </div>
      <div class="optionBox">
        <div>{{ detail.createTime }}</div>
        <img src="./img/look.png" alt="" />
        <div class="num1">{{ detail.readCount }}</div>
        <img src="./img/icon-like.png" alt="" />
        <div>{{ detail.praiseCount }}</div>
      </div>
      <div class="relevance" v-if="detail.isRelevance == 1">
        <div class="title">
          <span>关联功能</span>
        </div>
        <!-- 投票or问卷 -->
        <div
          class="content"
          v-if="detail.relevanceApp == 1 || detail.relevanceApp == 2"
          @click="toRouteTitle(detail.quTopicBean)"
        >
          <div class="content-bg">
            <img :src="require('./img/questionnaire.png')" alt="" />
          </div>
          <div class="content-item">
            <div class="item-l">
              <div class="item-t">
                {{ detail.quTopicBean.title }}
              </div>
              <div class="item-time">
                结束时间：{{ detail.quTopicBean.endDate }}
              </div>
              <div class="item-status">
                {{ textMap[detail.quTopicBean.quStatus][0] }}
              </div>
              <div class="item-result">
                {{ textMate(detail.quTopicBean, "btnText") }}
              </div>
            </div>
            <div class="item-r">
              <div
                class="item-img"
                style="border-radius: 60% 40% 0% 20% / 58% 20% 0% 42%"
              >
                <img :src="detail.quTopicBean.imageUrls" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- 抽奖 -->
        <div
          class="content"
          v-if="detail.relevanceApp == 3"
          @click="toLuckyDrawDetail(detail.lotteryInfo)"
        >
          <div class="content-bg">
            <img :src="require('./img/luckyDraw.png')" alt="" />
          </div>
          <div class="content-item">
            <div class="item-l">
              <div class="item-t">
                {{ detail.lotteryInfo.lotteryName }}
              </div>
              <div class="item-time">
                抽奖开始时间：{{ detail.lotteryInfo.startTime }}
              </div>
              <div class="item-time">
                开奖时间：{{ detail.lotteryInfo.endTime }}
              </div>
              <div class="item-status">
                {{ textFormat(detail.lotteryInfo.status) }}
              </div>
              <div
                class="item-result draw-result"
                v-if="detail.lotteryInfo.status == 4"
              >
                查看结果
              </div>
            </div>
            <div class="item-r">
              <div
                class="item-img"
                style="border-radius: 60% 40% 0% 20% / 58% 20% 0% 42%"
              >
                <img :src="detail.lotteryInfo.image" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- 活动 -->
        <div
          class="content"
          v-if="detail.relevanceApp == 5"
          @click="toRouteActivity(detail.activity)"
        >
          <div class="content-bg">
            <img :src="require('./img/active.png')" alt="" />
          </div>
          <div class="content-item">
            <div class="item-l">
              <div class="item-t activity">
                {{ detail.activity.activityName }}
              </div>
              <div class="remark">
                {{ detail.activity.activityAddress }}
              </div>
              <div class="item-time activity-applyStar">
                报名开始时间：{{ detail.activity.applyStartDate }}
              </div>
              <div class="item-time activity-start">
                活动开始时间：{{ detail.activity.startDate }}
              </div>
              <div class="item-result active-result">
                {{
                  getOption(
                    detail.activity.activityStatus,
                    detail.activity.hasFeedback,
                    detail.activity
                  )
                }}
              </div>
            </div>
            <div class="item-r" style="">
              <div
                class="item-img"
                style="border-radius: 60% 40% 0% 20% / 58% 20% 0% 42%"
              >
                <img :src="detail.activity.activityPicture" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- 商城 -->
        <div
          class="content shop"
          v-if="detail.relevanceApp == 4"
          @click="toShopDetail(detail.goodsBean)"
        >
          <div class="content-bg">
            <img :src="require('./img/shop.png')" alt="" />
          </div>
          <div class="content-item">
            <div class="item-l">
              <div class="item-t shop-t">
                {{ detail.goodsBean.title }}
              </div>
              <div class="shop-price">
                {{ detail.goodsBean.pointsPrice
                }}{{
                  detail.goodsBean.goodsClassify == 1 ? "珍珠贝" : "志愿珍珠贝"
                }}
              </div>
              <!-- <div class="item-time activity-applyStar">
                  报名开始时间：{{ detail.activity.applyStartDate }}
                </div>
                <div class="item-time activity-start">
                  活动开始时间：{{ detail.activity.startDate }}
                </div> -->
              <div class="shop-result">去兑换</div>
            </div>
            <div class="item-r">
              <div
                class="item-img shop-img"
                style="border-radius: 60% 40% 0% 20% / 58% 20% 0% 42%"
              >
                <img :src="detail.goodsBean.imageUrl" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="greyLine"><div class="greyLineBox"></div></div>
      <div class="comments">
        <header>
          <span>评论</span><span>{{ commentsData.commentCount || "0" }}</span>
        </header>
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="comments-main"
            v-for="(comment, index) in commentList"
            :key="index"
          >
            <div class="userPhoto">
              <img
                :src="
                  comment.avatar
                    ? comment.avatar
                    : require('@/assets/img/default_avatar.png')
                "
                alt=""
              />
            </div>
            <div class="userInfo">
              <div class="info-title">{{ comment.userName }}</div>
              <div class="info-content">
                {{ comment.commentContent }}
              </div>
              <div class="picList">
                <div
                  v-for="(item, index) in comment.commentPic.split(',')"
                  :key="index"
                  @click="showImgClick(comment.commentPic.split(','))"
                >
                  <img style="width: 100%" :src="item" alt="" />
                </div>
              </div>

              <div
                class="info-reply"
                v-if="JSON.stringify(comment.latestReply) != '{}'"
              >
                <div class="info-reply__content">
                  <span class="content-user">{{
                    comment.latestReply.fromUserName
                  }}</span>
                  <span
                    class="content-to-user"
                    v-if="comment.latestReply.toUserName"
                  >
                    回复 @{{ comment.latestReply.toUserName }}</span
                  >
                  <span class="content-colon">：</span>
                  <span class="content-text">
                    {{ comment.latestReply.replyContent }}
                  </span>
                </div>
                <div
                  class="info-reply__btn"
                  v-show="comment.replyCount > 0"
                  @click="toRouteReply(comment)"
                >
                  <span>共 {{ comment.replyCount }} 条回复</span>
                  <img src="./img/icon-arrow.png" alt="" />
                  <!-- <i class="el-icon-arrow-right"></i> -->
                </div>
              </div>
              <div class="info-foot">
                <div class="foot-time">{{ comment.createTime }}</div>
                <div class="foot-wrap">
                  <div class="iconText" @click="toComments(comment)">
                    <div class="icon">
                      <img src="./img/icon-msg.png" />
                    </div>
                  </div>
                  <div class="iconText" @click="toClickLike(comment)">
                    <div class="icon icon-up">
                      <img
                        v-if="!comment.hasPraised"
                        src="./img/icon-like.png"
                      />
                      <img v-else src="./img/icon-like-red.png" alt="" />
                    </div>
                    <div class="text">
                      <span :class="{ hasPraised: comment.hasPraised }">{{
                        comment.praiseCount
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-list>
      </div>
    </v-refresh>
    <footer>
      <div class="footer-wrap">
        <div class="comment" @click="toComments(null)">
          <div>发表评论</div>
        </div>
        <div class="hot" @click="toClickLike(null)">
          <img v-if="!detail.hasPraised" src="./img/dianzan.png" />
          <img v-else src="./img/dianzanS.png" alt="" />
        </div>
      </div>
    </footer>
    <v-comments
      ref="vComments"
      v-model="text"
      isAutoClose
      :show.sync="isShowComments"
      :maxCount="3"
      @acceptSend="acceptSend(arguments)"
      :showUploadImgBtn="showUploadImgBtn"
    ></v-comments>
    <v-picList v-model="showImg" :imgList="imgList"> </v-picList>
    <div class="black_layer" v-if="isShowDialog">
      <div class="dialog_box">
        <div class="top_message">
          涉及小区重大{{
            detail.relevanceApp == 1 ? "投票" : "问卷"
          }}的表决权，<br />
          仅限于产权人及其配偶才{{
            detail.relevanceApp == 1 ? "有投票权" : "可回答"
          }}
        </div>
        <div class="bottom_message">
          <span>
            <span>{{ text1 }}</span>
            <span>{{ text2 }}</span>
            <span
              v-if="
                (userInfo.identity == 0 || userInfo.identity == 1) &&
                dialogType == -1
              "
              style="color: #327eff"
              @click="goBinding()"
              >前往绑定</span
            >
            <span
              v-if="
                (userInfo.identity == 0 || userInfo.identity == 1) &&
                dialogType == 2
              "
              style="color: #901818"
              @click="goBinding()"
              >审核失败</span
            >
            <span
              v-if="
                (userInfo.identity == 0 || userInfo.identity == 1) &&
                dialogType == 0
              "
              style="color: #ffb92d"
              >审核中</span
            >
          </span>
        </div>
        <img
          @click.stop="isShowDialog = false"
          :src="require('./img/close_icon.png')"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getTopicInfoByIdURL,
  cancelPraiseURL,
  addPraiseURL,
  getTopicCommentList,
  addCommentURL,
  getUserHouseOwnerAuditStatusUrl,
  userInfoUrl,
} from "./api";
import { vComments } from "@/components/control/index";
import { activityStatus } from "./map";
import { getOption, handleImg } from "@/utils/utils.js";
import wx from "weixin-js-sdk";
import { toRegister } from "@/utils/common";

export default {
  name: "propertyNoticeDetail",
  components: {
    vComments,
  },
  data() {
    return {
      showUploadImgBtn: false,
      showImg: false,
      imgList: [],
      id: "", // 记录详情页id
      isLoading: false,
      finished: false,
      finishedText: "没有更多了",
      curPage: 1,
      pageSize: 20,
      detail: {},
      commentList: [],
      commentsData: {},
      isShowComments: false,
      curReply: null, // 用于记录回复的哪一条评论
      text: "",
      commentPic: "",
      text1: "",
      text2: "",
      dialogType: null,
      isShowDialog: false,
      textMap: {
        // 问卷状态
        1: ["未开始", "#EFEFEF", "#AAAAAA", "#D1D1D1"],
        2: ["进行中", "#FFF3EB", "#FE6F16", "#FFC7A4"],
        3: ["已结束", "#EFEFEF", "#AAAAAA", "#D1D1D1"],
      },
      userInfo: {},
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    const { id } = this.$route.query;
    this.id = id;
    this.getDetail();
    this.getUserInfo();
    // this.onLoad();
  },
  methods: {
    // 获取用户信息
    async getUserInfo() {
      if (!this.userId || this.userId == "0") {
        return;
      }
      let params = {
        userId: this.userId,
      };
      const res = await this.$axios.get(userInfoUrl, {
        params,
      });
      if (res.code === 200) {
        this.userInfo = res.data;
        console.log(this.userInfo);
      }
    },
    //活动状态
    getOption(status, hasFeedback, item) {
      if (hasFeedback && hasFeedback == 1) {
        return "活动回顾";
      }
      if (item.applyUserCount >= item.limitCount && item.limitCount != 0) {
        return "报名已满";
      }
      if (getOption(status, activityStatus, "key")["value"]) {
        return getOption(status, activityStatus, "key")["value"];
      } else {
        return "";
      }
    },
    //抽奖状态
    textFormat(status) {
      let x = null;
      switch (status) {
        case 2:
          x = "立即参与";
          break;
        case 3:
          x = "待开奖";
          break;
        case 4:
          x = "已结束";
          break;
        case 1:
          x = "未开始";
          break;
        case 99:
          x = "已下架";
          break;
        case 5:
          x = "谢谢参与";
          break;
        case 6:
          x = "中奖啦";
          break;
      }
      return x;
    },
    // classFormat(status) {
    //   let x = null;
    //   switch (status) {
    //     case 2:
    //       x = "";
    //       break;
    //     case 3:
    //       x = "white";
    //       break;
    //     case 4:
    //       x = "grey";
    //       break;
    //     case 1:
    //       x = "grey";
    //       break;
    //     case 99:
    //       x = "grey";
    //       break;
    //     case 5:
    //       x = "yellow";
    //       break;
    //     case 6:
    //       x = "orange";
    //       break;
    //   }
    //   return x;
    // },
    // 问卷状态
    textMate(item, str) {
      const { quStatus, hasAnswer } = item;
      const result = {
        btnText: ["填写问卷", "已填写", "查看结果", "投票", "已投票"],
        style: [
          {
            background: "linear-gradient(180deg, #73A8FF 0%, #2E7BFF 100%)",
            color: "#FFFFFF",
          },
          {
            background: "linear-gradient(180deg, #FDC76F 0%, #FAAD3D 100%)",
            color: "#FFFFFF",
          },
          {
            background: "linear-gradient(180deg, #EDEDED 0%, #D8D8D8 100%)",
            color: "#00000040",
          },
          {
            background: "linear-gradient(180deg, #73A8FF 0%, #2E7BFF 100%)",
            color: "#FFFFFF",
          },
          {
            background: "linear-gradient(180deg, #FDC76F 0%, #FAAD3D 100%)",
            color: "#FFFFFF",
          },
        ],
      };
      if (quStatus == 2 && hasAnswer == 2) {
        // 进行中且未回答
        if (this.detail.relevanceApp == 1) {
          return result[str][3];
        } else {
          return result[str][0];
        }
      } else if (quStatus == 2 && hasAnswer == 1) {
        // 进行中但已回答
        if (this.detail.relevanceApp == 1) {
          return result[str][4];
        } else {
          return result[str][1];
        }
      } else if (quStatus == 3) {
        // 已结束
        return result[str][2];
      }
    },
    // 点击跳转话题详情页
    async toRouteTitle(item) {
      /* 仅业主可答题且identity不为0
         identity 0业主、1家属、2租客、3访客、4上门服务人员
      */
      if (item.isOnlyHouseOwner == 1) {
        await this.getUserHouseOwnerAuditStatus();
        if (this.userInfo.identity != 0 && this.userInfo.identity != 1) {
          this.text1 = "您不满足产权人及其配偶身份，";
          this.text2 =
            this.detail.relevanceApp == 1 ? "无权投票~" : "无权填写问卷";
          this.isShowDialog = true;
          return;
        } else {
          if (this.dialogType != 1) {
            this.text1 = "您还未绑定产权人信息，";
            this.isShowDialog = true;
            return;
          }
        }
      }
      if (item.quStatus === 1) {
        return;
      }
      // quStatus 状态：1:未开始;2:进行中;3:已结束
      let params = {
        id: item.id,
        // isView: item.quStatus !== 2 || item.hasAnswer === 1, // 非进行中的仅有查看权限, 1 已参与 2 未参与  已参与人也仅有查看权限
        hasAnswer: item.hasAnswer,
      };
      if (this.detail.relevanceApp == 2) {
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/questionnaireDetail/index?params=${JSON.stringify(
              params
            )}`,
          });
        }
      } else if (this.detail.relevanceApp == 1) {
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/voteDetail/index?params=${JSON.stringify(params)}`,
          });
        }
      }
    },
    //查询产权人认证审核状态
    async getUserHouseOwnerAuditStatus() {
      if (
        await toRegister(this.$route.path, this.$route.query, "热门活动报名")
      ) {
        let params = {
          userId: this.userId,
          houseId: this.houseId,
        };
        this.$axios
          .get(`${getUserHouseOwnerAuditStatusUrl}`, { params: params })
          .then((res) => {
            if (res.code == 200) {
              this.dialogType = res.data;
            }
          });
      }
    },
    //认证产权人身份跳转
    goBinding() {
      this.$router.replace({
        name: "identityAuthentication",
        query: {
          type: 1,
        },
      });
    },
    //抽奖跳转
    toLuckyDrawDetail(item) {
      if (item.status == 1) {
        this.$toast("抽奖未开始");
      } else {
        this.$router.push({
          name: "luckyDrawDetail",
          query: {
            id: item.id,
          },
        });
      }
    },
    // 活动跳转
    toRouteActivity(item) {
      this.$router.push({
        name: "activitySquareDetail",
        query: { activityId: item.activityId },
      });
    },
    // 商城跳转
    toShopDetail(item) {
      this.$router.push({
        name: "mallDetail",
        query: {
          id: item.id,
          type: item.goodsClassify == 1 ? "珍珠贝商城" : "志愿珍珠贝商城",
        },
      });
    },
    showImgClick(list) {
      this.showImg = true;
      this.imgList = list;
    },
    // 获取详情
    getDetail() {
      this.$toast.loading({
        // duration: 1500, // 持续展示 toast
        forbidClick: true,
        message: "请求中...",
      });
      this.$axios
        .get(getTopicInfoByIdURL, {
          params: {
            id: this.id,
            userId: this.userId,
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.detail = res.data;
            // 投票or问卷
            if (
              Object.keys(this.detail.quTopicBean).length > 0 &&
              this.detail.quTopicBean.imageUrls
            ) {
              this.detail.quTopicBean.imageUrls = handleImg(
                240,
                240,
                this.detail.quTopicBean.imageUrls
              );
            }
            // 抽奖
            if (
              Object.keys(this.detail.lotteryInfo).length > 0 &&
              this.detail.lotteryInfo.image
            ) {
              this.detail.lotteryInfo.image = handleImg(
                240,
                240,
                this.detail.lotteryInfo.image
              );
            }
            // 活动
            if (
              Object.keys(this.detail.activity).length > 0 &&
              this.detail.activity.activityPicture
            ) {
              this.detail.activity.activityPicture = handleImg(
                240,
                240,
                this.detail.activity.activityPicture
              );
            }
            //商城
            if (
              Object.keys(this.detail.goodsBean).length > 0 &&
              this.detail.goodsBean.imageUrl
            ) {
              this.detail.goodsBean.imageUrl = handleImg(
                240,
                240,
                this.detail.goodsBean.imageUrl
              );
            }
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicCommentList, {
          params: {
            topicId: this.id,
            userId: this.userId,
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            this.commentsData = res.data;
            if (this.curPage === 1) {
              this.commentList = res.data.records;
            } else {
              const list = this.commentList.map((v) => v.commentId);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.commentId) || this.commentList.push(item);
              });
            }
            this.curPage++;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    // 点赞和取消点赞
    toClickLike(data) {
      if (this.detail.closePraise === 1) {
        this.$toast({
          duration: 1500,
          message: "点赞功能已关闭",
        });
        return;
      }
      // item 存在则是评论点赞，反之则是话题点赞
      const item = data || this.detail;
      this.$axios
        .post(item.hasPraised ? cancelPraiseURL : addPraiseURL, null, {
          params: {
            targetId: data ? item.commentId : item.id, // commentId 评论主键id
            userId: this.userId,
            tenantId: this.tenantId || undefined,
            communityId: this.communityId || undefined,
            roomId: this.houseId || undefined,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            console.log(res, "----");
            if (item.hasPraised) {
              // 取消点赞
              item.hasPraised = false;
              item.praiseCount -= 1;
            } else {
              // 点赞
              item.hasPraised = true;
              item.praiseCount += 1;
            }
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          // this.isLoading = false;
        });
    },
    // 点击评论图标跳出评论控件
    toComments(item) {
      if (this.detail.closeComment === 1) {
        this.$toast({
          duration: 1500,
          message: "评论功能已关闭",
        });
        return;
      }
      this.curReply = item;
      this.isShowComments = true;
      // 获取焦点
      setTimeout(() => {
        this.$refs.vComments.getFocus();
      }, 500);
    },
    // 接受评论组件发送过来的信息
    acceptSend(data) {
      if (data[1].length > 0) {
        this.commentPic = data[1];
      }
      console.log(data);
      console.log(data[0].trim);
      console.log(data[0].trim.length);
      if (data[0].length == 0 && data[1].length == 0) {
        this.$toast({
          message: "请输入内容或上传图片",
          duration: 1500,
        });
        return;
      }
      const item = this.curReply;
      let params = {};
      if (item) {
        // 如果存在则为回复
        params = {
          skipImageScan: "skip",
          topicId: item.topicId, // 话题id
          commentId: item.commentId, // 评论id
          // parentId: "", // 上级回复id 预留字段，评论类型为2的场合才可能有值，将来如果要实现回复树状展示时可以利用
          commentType: 1, // 评论类型 0：针对话题的评论；1：针对评论的回复；2：针对回复的回复
          commentContent: this.text, // 评论内容
          fromUser: this.userId, // 评论人ID
          toUser: null, // 被回复人ID
          commentPic: this.commentPic,
        };
      } else {
        // 反之则为评论
        console.log(this.id);
        params = {
          skipImageScan: "skip",
          topicId: this.id, // 话题id
          commentId: null, // 评论id
          // parentId: "", // 上级回复id 预留字段，评论类型为2的场合才可能有值，将来如果要实现回复树状展示时可以利用
          commentType: 0, // 评论类型 0：针对话题的评论；1：针对评论的回复；2：针对回复的回复
          commentContent: this.text, // 评论内容
          fromUser: this.userId, // 评论人ID
          toUser: null, // 被回复人ID
          commentPic: this.commentPic,
        };
      }
      // 1. 调接口
      this.$toast.loading({
        // duration: 1500, // 持续展示 toast
        forbidClick: true,
        message: "发送中...",
      });
      this.$axios
        .post(addCommentURL, {
          ...params,
          tenantId: this.tenantId || undefined,
          communityId: this.communityId || undefined,
          roomId: this.houseId || undefined,
        })
        .then((res) => {
          if (res.code === 200) {
            // 2. 关闭弹框
            this.isShowComments = false;
            // 3. 关闭后清楚数据
            this.text = "";
            this.commentPic = "";
            // 4. 调评论接口
            this.onRefresh();
            // item.commentCount += 1;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    // 跳转回复页面
    toRouteReply(item) {
      this.$router.push({
        name: "replyDetail",
        query: {
          title: `${item.replyCount}条回复`,
          detail: JSON.stringify(item),
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  min-height: 100vh;
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
  .blackBg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .black_layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 0 75px;
    box-sizing: border-box;
    .dialog_box {
      background: #ffffff;
      border-radius: 16px;
      filter: blur(0px);
      width: 100%;
      position: relative;
      top: 40%;
      transform: translateY(-40%);
      image {
        display: block;
        position: absolute;
        width: 76px;
        height: 76px;
        bottom: -144px;
        left: 50%;
        transform: translateX(-50%);
      }
      .bottom_message {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
        text-align: center;
        padding-bottom: 48px;
        z-index: 10000000000;
      }
      .top_message {
        font-size: 30px;
        line-height: 46px;
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
        padding: 70px 0;
      }
    }
  }
  /* 兼容 iOS >= 11.2 */
  .infoBox {
    width: 100%;
    .banner {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .main {
    box-sizing: border-box;
    padding: 0px 0px 128px 0px;
    margin-top: 32px;
    .greyLine {
      width: 100%;
      height: 32px;
      position: relative;
      .greyLineBox {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f5f5f5;
      }
    }
    .optionBox {
      display: flex;
      font-size: 24px;
      color: #aaaaaa;
      align-items: center;
      box-sizing: border-box;
      padding: 20px 48px;
      img {
        display: block;
        width: 32px;
        margin-right: 8px;
      }
      div:nth-child(1) {
        margin-right: 222px;
      }
      .num1 {
        margin-right: 62px;
      }
    }
    .content {
      width: 100%;
      overflow-x: hidden;
      box-sizing: border-box;
      margin-bottom: 80px;
      font-size: 32px;
      line-height: 1.5;
      color: #333333;
      word-wrap: break-word;
      padding: 0 32px;

      > .text-empty {
        height: 96px;
        line-height: 96px;
        background: #eeeeee;
        text-align: center;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      ::v-deep img {
        width: 100%;
      }
    }
    .relevance {
      padding: 0 32px;
      width: 100%;
      box-sizing: border-box;
      .title {
        margin-bottom: 10px;
        padding-left: 18px;
        display: block;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #1a1c34;
        line-height: 50px;

        span {
          position: relative;
          z-index: 1;
          &::before {
            z-index: -1;
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            margin: auto;
            width: 120%;
            height: 14px;
            background: #e2e2e2;
            border-radius: 7px;
          }
        }
      }
      .content {
        width: 100%;
        padding: 36px 0 0 40px;
        border-radius: 16px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        .content-bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .content-item {
          position: relative;
          z-index: 1;
          box-sizing: border-box;
          width: 100%;
          display: flex;
          .item-l {
            padding-bottom: 30px;
            flex: 1;
            .item-t {
              font-size: 32px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 42px;
              margin-bottom: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .shop-price {
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #528edd;
              line-height: 40px;
              text-shadow: 0px 4px 18px rgba(239, 239, 239, 0.5);
            }
            .shop-t {
              color: #528edd;
            }
            .activity {
              margin-bottom: 8px;
              color: #68421c;
            }
            .remark {
              white-space: normal;
              word-break: break-all;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ab855f;
              line-height: 34px;
            }
            .item-time {
              height: 34px;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 34px;
            }
            .activity-start {
              color: #6f4010;
              margin: 8px 0 22px 0;
            }
            .activity-applyStar {
              color: #6f4010;
            }
            .item-status {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 16px 0 26px 0;
              width: 110px;
              height: 40px;
              border-radius: 4px;
              border: 2px solid #ffffff;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 34px;
            }
            .item-result {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 150px;
              height: 46px;
              background: rgba(255, 255, 255, 0.8);
              border-radius: 23px;
              font-size: 24px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #1272ee;
            }
            .shop-result {
              margin-top: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 180px;
              height: 54px;
              background: #e4f1ff;
              box-shadow: 0px 4px 18px 0px rgba(239, 239, 239, 0.5);
              border-radius: 27px;
              font-size: 28px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #3c8fe4;
              text-shadow: 0px 4px 18px rgba(239, 239, 239, 0.5);
            }
            .active-result {
              background: #ffe7d3;
              color: #6f4010;
            }
          }
          .item-r {
            display: flex;
            align-items: flex-end;
            width: 240px;
            overflow: hidden;
            .item-img {
              width: 240px;
              height: 240px;
              border: 8px solid #fff;
              border-bottom: none;
              border-right: none;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .shop-img {
              border: 8px solid #e4f1fe;
            }
          }
        }
      }
      .shop {
        box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.3);
      }
    }

    .comments {
      > header {
        font-size: 32px;
        margin-left: 32px;
        padding-top: 20px;
        box-sizing: border-box;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
        color: #1a1c34;
        line-height: 44px;

        > span {
          &:nth-of-type(1) {
            margin-right: 8px;
          }

          // &:nth-of-type(2) {
          // }
        }
      }

      .comments-main {
        display: flex;
        margin-top: 32px;
        padding: 0 32px;
        box-sizing: border-box;

        .userPhoto {
          height: 72px;
          width: 72px;
          min-width: 72px;
          overflow: hidden;
          border-radius: 50%;

          > img {
            width: 100%;
            height: 100%;
          }
        }

        .userInfo {
          word-wrap: break-word;
          flex: 1;
          margin-left: 16px;
          max-width: calc(100% - 88px);
          .picList {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            div {
              width: 30%;
              margin-bottom: 10px;
              margin-right: 10px;
            }
          }
          .info-title {
            box-sizing: border-box;
            width: 100%;
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: bold;
            color: #333333;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .info-content {
            font-size: 32px;
            margin-top: 8px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 44px;
          }

          .info-reply {
            background: #f7f7f7;
            line-height: 40px;
            padding: 24px;
            margin-top: 24px;
            word-wrap: break-word;
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;

            .info-reply__content {
              .content-user,
              .content-colon {
                font-weight: bold;
                color: #333333;
              }

              .content-to-user {
                color: blur;
              }

              .content-text {
                font-weight: 400;
                color: #666666;
                vertical-align: middle;
              }
            }

            .info-reply__btn {
              margin-top: 8px;
              white-space: nowrap;
              font-weight: bold;
              color: #333333;
              display: flex;
              align-items: center;

              > span {
                font-weight: bold;
                color: #333333;
              }

              img {
                width: 24px;
                height: 24px;
              }
            }
          }

          .info-foot {
            margin-top: 24px;
            line-height: 32px;
            display: flex;

            .foot-time {
              color: #a8a8a8;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #a8a8a8;
            }

            .foot-wrap {
              flex: 1;
              display: flex;
              justify-content: flex-end;

              > .iconText {
                display: flex;
                // margin-right: 32px;
                color: #a8a8a8;
                align-items: center;

                &:not(:last-child) {
                  margin-right: 32px;
                }

                .icon {
                  width: 32px;
                  height: 32px;
                  overflow: hidden;

                  &.icon-up {
                    > img {
                      margin-top: -2px;
                    }
                  }

                  > img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .text {
                  margin-left: 4px;
                  display: flex;
                  align-items: center;
                  height: 32px;

                  span {
                    &.hasPraised {
                      color: #ff853a;
                    }

                    // color: #ff853a;
                    font-size: 24px;
                    line-height: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  footer {
    z-index: 2;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    // display: flex;
    // padding: 24px 0 24px;
    box-shadow: inset 0px 2px 0px 0px rgba(230, 230, 230, 1);
    background: #ffffff;
    padding-bottom: constant(safe-area-inset-bottom);
    /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom);

    /* 兼容 iOS >= 11.2 */
    .footer-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px 32px 30px;
      box-sizing: border-box;
      .comment {
        flex: 1;
        margin: 8px 0;
        height: 66px;
        background: linear-gradient(180deg, #9591ff 0%, #605cff 100%);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 32px;
        div {
          vertical-align: middle;
          margin-left: 8px;
          line-height: 40px;
        }
      }
      .hot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #323334;
        margin-left: 26px;
        img {
          width: 46px;
          height: 42px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
